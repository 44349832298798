import PropTypes from 'prop-types';
import clsx from 'clsx';
import Container from '@/components/Container';
import Brand from '@/view/Brand';
import Navbar from '@/view/Navbar';
import InnerRefShape from '@/shapes/InnerRef';
import HeaderElement from './HeaderElement';

const defaultProps = {
  isSticky: false,
  fixed: false,
  style: {},
};

const propTypes = {
  isSticky: PropTypes.bool,
  fixed: PropTypes.bool,
  style: PropTypes.object,
  target: InnerRefShape,
};

export default function HeaderContent({ isSticky, fixed, style, ...rest }) {
  return (
    <HeaderElement
      className={clsx(isSticky && 'shadow-xl')}
      fixed={fixed}
      style={style}
      {...rest}
    >
      <Container>
        <nav className="flex items-center w-full space-x-4" role="navigation">
          <div className="relative z-50">
            <Brand showPartialWithAnimation={isSticky} />
          </div>
          <Navbar />
        </nav>
      </Container>
    </HeaderElement>
  );
}

HeaderContent.defaultProps = defaultProps;
HeaderContent.propTypes = propTypes;
