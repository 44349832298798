import PropTypes from 'prop-types';
import Sticky from '@/components/Sticky';
import HeaderContent from './HeaderContent';

export default function Header({ fixed, sticky, hasAnAnnouncement }) {
  if (sticky === false) {
    return <HeaderContent fixed={fixed} />;
  }

  return (
    <Sticky topOffset={hasAnAnnouncement ? 50 : 20}>
      {({ isSticky, style }) => (
        <div className="relative z-50 lg:sticky lg:top-0" style={style}>
          <HeaderContent fixed={fixed} isSticky={isSticky} />
        </div>
      )}
    </Sticky>
  );
}

Header.defaultProps = {
  fixed: false,
  sticky: false,
  hasAnAnnouncement: false,
};

Header.propTypes = {
  fixed: PropTypes.bool,
  sticky: PropTypes.bool,
  hasAnAnnouncement: PropTypes.bool,
};
