import PropTypes from 'prop-types';
import useHover from '@/hooks/useHover';
import clsx from 'clsx';
import HeaderHoverBack from './HeaderHoverBack';

const defaultProps = {
  fixed: false,
  className: null,
  style: {},
};

const propTypes = {
  fixed: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
};

export default function HeaderElement({
  fixed,
  className,
  style: defaultStyle,
  children,
}) {
  const [target, isHovering] = useHover();

  let style = defaultStyle || {};

  if (fixed === false) {
    style = {
      ...style,
      backdropFilter: 'blur(20px) saturate(120%)',
      backgroundColor: 'rgba(255, 255, 255, 0.65)',
    };
  }

  return (
    <header
      className={clsx(
        'z-50 flex w-full items-center transition-all duration-500 ease-in-out lg:h-20',
        fixed === false && 'lg:sticky lg:top-0',
        fixed === true && 'lg:absolute lg:left-0 lg:top-0 lg:right-0',
        className
      )}
      style={style}
      ref={target}
    >
      {isHovering === true && <HeaderHoverBack headerIsHover />}
      {children}
    </header>
  );
}

HeaderElement.defaultProps = defaultProps;
HeaderElement.propTypes = propTypes;
