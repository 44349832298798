import PropTypes from 'prop-types';
import clsx from 'clsx';
import Link from '@/components/Link';

const defaultProps = {
  url: null,
  level: 1,
};

const propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  level: PropTypes.oneOf([1, 2]),
};

export default function MobileMenuItem({ title, url, level }) {
  let commonClasses = [
    'flex items-center px-4',
    level === 1 && 'py-3 text-lg font-medium',
    level === 2 && 'py-2 text-base text-gray-700',
  ];

  if (url === undefined || url === null || url === '') {
    return <span className={clsx(commonClasses)}>{title}</span>;
  }

  return (
    <Link
      href={url}
      className={clsx(
        commonClasses,
        'focus:shadow-outline-green hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900'
      )}
    >
      {title}
    </Link>
  );
}

MobileMenuItem.defaultProps = defaultProps;
MobileMenuItem.propTypes = propTypes;
