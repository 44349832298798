import dynamic from 'next/dynamic';
import Head from 'next/head';
import BasicLayout from '@/layouts/BasicLayout';
import moment from 'moment';
import StickyContainer from '@/components/Sticky/StickyContainer';
import { withAnalytics } from '@/domain/Analytics';
import { ApolloProvider } from '@apollo/client';
import { CookiesProvider } from 'react-cookie';
import { ToasterProvider } from '@/components/Toaster';
import { LayoutProvider } from '@/layouts/LayoutContext';
import { useApollo } from '@/api/client';
import '@/helpers/find.polyfill';

const ApplicationCommons = dynamic(() => import('@/domain/ApplicationCommons'));

import '../css/main.css';

moment.locale('es');

const __SITE_TITLE__ = 'Asociación Iberoamericana de Scrum';

function App({ Component, pageProps, router }) {
  const apolloClient = useApollo(pageProps.initialApolloState || {});

  const Layout = Component.layoutProps?.Layout || BasicLayout;

  const layoutProps = Component.layoutProps ? Component.layoutProps : {};

  const meta = layoutProps?.meta || {};

  const title = meta.metaTitle || meta.title || __SITE_TITLE__;

  const description =
    meta.metaDescription ||
    meta.description ||
    'La entidad certificadora de excelencia para hispanohablantes.';

  return (
    <LayoutProvider>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>{title}</title>
        <meta key="og:title" property="og:description" content={title} />
        <meta
          key="og:description"
          property="og:description"
          content={description}
        />
        <meta
          key="og:url"
          property="og:url"
          content={`https://aibes.org${router.pathname}`}
        />
        <meta key="og:type" property="og:type" content="article" />
      </Head>
      <ApolloProvider client={apolloClient}>
        <CookiesProvider>
          <StickyContainer>
            <Layout {...layoutProps}>
              <Component {...pageProps} />
            </Layout>
          </StickyContainer>
          <ToasterProvider />
        </CookiesProvider>
      </ApolloProvider>
      <ApplicationCommons />
    </LayoutProvider>
  );
}

export default withAnalytics(App);
