import PropTypes from 'prop-types';
import MainMenu from '@/view/MainMenu';
import NavbarActions from '@/view/NavbarActions';

export default function DesktopMenu({ onHoverChange }) {
  return (
    <div className="flex w-full flex-1 items-center">
      <div className="flex-1">
        <MainMenu onHoverChange={onHoverChange} />
      </div>
      <div className="relative z-40">
        <NavbarActions />
      </div>
    </div>
  );
}

DesktopMenu.defaultProps = {
  onHoverChange: () => null,
};

DesktopMenu.propTypes = {
  onHoverChange: PropTypes.func,
};
