import Flex from '@/components/Flex';
import AccessAction from './AccessAction';
import CertificationsAction from './CertificationsAction';

export default function NavbarActions() {
  return (
    <Flex alignItems="center" className="space-x-2">
      <div className="hidden xl:block">
        <AccessAction />
      </div>
      <CertificationsAction />
    </Flex>
  );
}
