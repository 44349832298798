import PropTypes from 'prop-types';
import ListItem from '@/components/ListItem';
import UnorderedList from '@/components/UnorderedList';
import uniqueKey from '@/helpers/uniqueKey';
import MobileMenuItem from './MobileMenuItem';

const defaultProps = {
  items: [],
};

const propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string,
      slug: PropTypes.string,
    })
  ),
};

export default function MobileMenuItems({ items }) {
  return (
    <UnorderedList className="relative z-0 flex flex-col py-4 text-base">
      {items.map(({ title, url, slug }) => (
        <ListItem key={uniqueKey(slug)}>
          <MobileMenuItem title={title} url={url} level={2} />
        </ListItem>
      ))}
    </UnorderedList>
  );
}

MobileMenuItems.defaultProps = defaultProps;
MobileMenuItems.propTypes = propTypes;
