import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import NextLink from 'next/link';
import useHover from '@/hooks/useHover';
import renderLinkChildren from './renderLinkChildren';

const defaultProps = {
  title: '',
  className: '',
  children: null,
};

const propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

function Link({ href, title, className, children, innerRef, ...rest }) {
  const [ref, isHovering] = useHover(innerRef);

  // Ensure we have a valid url
  if (href === '' || href == null || href == undefined) {
    return null;
  }

  if (href.indexOf('//') !== -1 || href.indexOf('#') !== -1) {
    return (
      <a href={href} title={title} className={className} ref={ref} {...rest}>
        {renderLinkChildren({
          children,
          isHovering,
        })}
      </a>
    );
  }

  return (
    <NextLink href={href}>
      <a title={title} className={className} ref={ref} {...rest}>
        {renderLinkChildren({
          children,
          isHovering,
        })}
      </a>
    </NextLink>
  );
}

Link.defaultProps = defaultProps;
Link.propTypes = propTypes;

const LinkForwardRef = forwardRef((props, ref) => (
  <Link {...props} innerRef={ref} />
));

LinkForwardRef.displayName = 'LinkForwardRef';

export default LinkForwardRef;
