import { useRef, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import MenuItem from './MenuItem';

const DesktopMegaMenu = dynamic(() => import('@/view/DesktopMegaMenu'));

const defaultProps = {
  url: null,
  slug: null,
  hasItems: false,
  isHoverActive: false,
  onHoverChange: () => null,
};

const propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  slug: PropTypes.string,
  hasItems: PropTypes.bool,
  isHoverActive: PropTypes.bool,
  onHoverChange: PropTypes.func,
};

export default function MenuItemNormal({ title, url, slug, hasItems }) {
  const [isHover, setHover] = useState(false);

  const ref = useRef(null);

  const handleMouseOver = useCallback(() => setHover(true));
  const handleMouseOut = useCallback(() => setHover(false));

  const onMenuClick = useCallback(() => {
    handleMouseOut();
  }, []);

  useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener('mouseenter', handleMouseOver);
      node.addEventListener('mouseleave', handleMouseOut);

      return () => {
        node.removeEventListener('mouseenter', handleMouseOver);
        node.removeEventListener('mouseleave', handleMouseOut);
      };
    }
  }, [ref.current]);

  return (
    <MenuItem
      title={title}
      url={url}
      slug={slug}
      isHovering={isHover}
      innerRef={ref}
      hasItems={hasItems}
    >
      {hasItems && (
        <DesktopMegaMenu
          itemSlug={slug}
          isHover={isHover}
          onMenuHover={(isHover) => {
            if (isHover) {
              handleMouseOver();
            } else {
              handleMouseOut();
            }
          }}
          onMenuClick={onMenuClick}
        />
      )}
    </MenuItem>
  );
}

MenuItemNormal.defaultProps = defaultProps;
MenuItemNormal.propTypes = propTypes;
