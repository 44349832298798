import PropTypes from 'prop-types';
import MobileMenu from '@/view/MobileMenu';
import DesktopMenu from '@/view/DesktopMenu';

const defaultProps = {
  isMobile: false,
  onDesktopMenuItemHover: () => null,
  onMobileMenuItemClick: () => null,
};

const propTypes = {
  isMobile: PropTypes.bool,
  onDesktopMenuItemHover: PropTypes.func,
  onMobileMenuItemClick: PropTypes.func,
};

export default function Navbar({
  isMobile,
  onDesktopMenuItemHover,
  onMobileMenuItemClick,
}) {
  return (
    <div className="flex w-full items-center">
      {isMobile === false && (
        <DesktopMenu onHoverChange={onDesktopMenuItemHover} />
      )}
      {isMobile === true && <MobileMenu onItemClick={onMobileMenuItemClick} />}
    </div>
  );
}

Navbar.defaultProps = defaultProps;
Navbar.propTypes = propTypes;
