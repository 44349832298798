import PropTypes from 'prop-types';
import uniqueKey from '@/helpers/uniqueKey';
import navigation from '@/content/navigation.json';
import MobileMenuItem from './MobileMenuItem';
import MobileMenuItems from './MobileMenuItems';

export default function MobileMenu({ onItemClick }) {
  return (
    <ul
      className="flex w-full flex-col py-4 shadow-xl focus:outline-none"
      tabIndex="-1"
      onClick={onItemClick}
    >
      {navigation.primary.map(({ title, url, slug, items }) => (
        <li key={uniqueKey(slug)}>
          <MobileMenuItem title={title} url={url} />
          {items && items.length !== 0 && <MobileMenuItems items={items} />}
        </li>
      ))}
    </ul>
  );
}

MobileMenu.defaultProps = {
  onItemClick: () => null,
};

MobileMenu.propTypes = {
  onItemClick: PropTypes.func,
};
