import { useState, useCallback } from 'react';
import clsx from 'clsx';
import Container from '@/components/Container';
import Brand from '@/view/Brand';
import Navbar from '@/view/Navbar';

import HeaderMobileNavToggle from './HeaderMobileNavToggle';

const defaultProps = {};

const propTypes = {};

export default function HeaderMobile() {
  const [mobileMenuActive, setMenuActive] = useState(false);

  const onMenuToggle = useCallback(() => {
    setMenuActive((v) => !v);
  }, []);

  return (
    <header
      className={clsx(
        'relative z-50 flex w-full items-center',
        mobileMenuActive && 'shadow-xl'
      )}
    >
      <Container padding={false}>
        <nav className="flex w-full flex-col" role="navigation">
          <div className="relative z-50 flex w-full items-center px-4 py-2">
            <div className="flex-1">
              <Brand />
            </div>
            <HeaderMobileNavToggle onToggle={onMenuToggle} />
          </div>
          {mobileMenuActive && (
            <div className="relative z-0 flex-1 overflow-y-auto">
              <Navbar onMobileMenuItemClick={onMenuToggle} isMobile />
            </div>
          )}
        </nav>
      </Container>
    </header>
  );
}

HeaderMobile.defaultProps = defaultProps;
HeaderMobile.propTypes = propTypes;
