import toast from 'react-hot-toast';

export default function notify(message) {
  toast(message);
}

export function notifySuccess(message) {
  toast.success(message);
}

export function notifyError(message) {
  toast.error(message);
}

export function notifyLoading(message) {
  toast.loading(message);
}
