import { useMemo } from 'react';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

let apolloClient;

const ssrMode = typeof window === 'undefined';

const httpLink = createHttpLink({
  uri: ssrMode
    ? process.env.WP_GRAPHQL_HOST
    : process.env.NEXT_PUBLIC_WP_GRAPHQL_HOST,
});

const authLink = setContext((_, { headers }) => {
  const token =
    typeof window !== 'undefined' && window.localStorage !== undefined
      ? localStorage.getItem('auth-token')
      : null;

  if (token !== null) {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  }

  const authenticationToken = process.env.HTTP_AUTHORIZATION;
  if (authenticationToken !== null && authenticationToken !== undefined) {
    return {
      headers: {
        ...headers,
        authorization: `Basic ${authenticationToken}`,
      },
    };
  }
});

function createApolloClient() {
  return new ApolloClient({
    ssrMode,
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
}

export function initializeApollo(initialState = null, context = null) {
  const _apolloClient = apolloClient ?? createApolloClient(context);

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return _apolloClient;
  }

  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
}

export function useApollo(initialState = {}, context = null) {
  const store = useMemo(
    () => initializeApollo(initialState, context),
    [initialState]
  );
  return store;
}

export default function client(initialState = null) {
  return initializeApollo(initialState);
}
