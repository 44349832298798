import _includes from 'lodash/includes';

let generated = [];

const generate = () => Math.random().toString(36).substr(2, 9);

export default function uniqueKey(append = '') {
  let key = `${append}${generate()}`;

  while (_includes(generated, key)) {
    key = `${append}${generate()}`;
  }

  generated = [...generated, key];

  return key;
}
