import PropTypes from 'prop-types';

const defaultProps = {
  children: null,
};

const propTypes = {
  children: PropTypes.node,
};

export default function Main({ children }) {
  return (
    <main className="flex-1" role="main">
      {children}
    </main>
  );
}

Main.defaultProps = defaultProps;
Main.propTypes = propTypes;
