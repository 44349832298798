import Flex, {
  directionShape as FlexDirectionShape,
  alignItemsShape as FlexAlignItemsShape,
  justifyContentShape as FlexJustifyContentShape,
  shrinkShape as FlexShrinkShape,
} from './Flex';

export default Flex;

export {
  FlexDirectionShape,
  FlexAlignItemsShape,
  FlexJustifyContentShape,
  FlexShrinkShape,
};
