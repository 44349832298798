import React from 'react';

export default function createStrictContext({ name, errorMessage }) {
  const Context = React.createContext(undefined);
  Context.displayName = name;

  function useContext() {
    const context = React.useContext(Context);
    if (context === undefined) {
      throw new Error(
        errorMessage || `${name || ''} Context Provider is missing`
      );
    }
    return context;
  }

  return [Context.Provider, useContext];
}
