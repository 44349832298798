import PropTypes from 'prop-types';
import clsx from 'clsx';
import omit from 'lodash/omit';
import Container, {
  ContainerDefaultProps,
  ContainerPropTypes,
} from '@/components/Container';
import DynamicPropsInjection from '@/components/DynamicPropsInjection';

const directionShape = PropTypes.oneOf(['row', 'col']);

const alignItemsShape = PropTypes.oneOf([
  'stretch',
  'start',
  'center',
  'end',
  'baseline',
]);

const justifyContentShape = PropTypes.oneOf([
  'start',
  'center',
  'end',
  'between',
  'around',
]);

const shrinkShape = PropTypes.oneOf([1, true]);

const defaultProps = {
  ...ContainerDefaultProps,
  direction: null,
  alignItems: null,
  justifyContent: null,
  shrink: null,
  inline: false,
  isFull: false,
  inject: false,
  className: '',
  children: null,
};

const propTypes = {
  ...ContainerPropTypes,
  direction: directionShape,
  alignItems: alignItemsShape,
  justifyContent: justifyContentShape,
  shrink: shrinkShape,
  inline: PropTypes.bool,
  isFull: PropTypes.bool,
  inject: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default function Flex({
  direction,
  alignItems,
  justifyContent,
  shrink,
  inline,
  isFull,
  inject,
  className,
  tabIndex,
  role,
  children,
  ...rest
}) {
  let classes = [className, !inline ? 'flex' : 'flex-inline'];

  if (direction) {
    classes = [
      ...classes,
      direction === 'row' && 'flex-row',
      direction === 'col' && 'flex-col',
    ];
  }

  if (alignItems) {
    classes = [
      ...classes,
      alignItems === 'stretch' && 'items-stretch',
      alignItems === 'start' && 'items-start',
      alignItems === 'center' && 'items-center',
      alignItems === 'end' && 'items-end',
      alignItems === 'baseline' && 'items-baseline',
    ];
  }

  if (justifyContent) {
    classes = [
      ...classes,
      justifyContent === 'start' && 'justify-start',
      justifyContent === 'center' && 'justify-center',
      justifyContent === 'end' && 'justify-end',
      justifyContent === 'between' && 'justify-between',
      justifyContent === 'around' && 'justify-around',
    ];
  }

  if (shrink) {
    classes = [...classes, shrink && 'flex-shrink-0', !shrink && 'flex-shrink'];
  }

  if (isFull) {
    classes = [...classes, 'w-full'];
  }

  if (inject) {
    const dynamicProps = omit(rest, Object.keys(ContainerPropTypes));

    return (
      <DynamicPropsInjection className={clsx(classes)} props={dynamicProps}>
        {children}
      </DynamicPropsInjection>
    );
  }

  return (
    <Container
      className={clsx(classes)}
      padding={false}
      maxWidth="max-w-none"
      tabIndex={tabIndex}
      role={role}
    >
      {children}
    </Container>
  );
}

Flex.defaultProps = defaultProps;
Flex.propTypes = propTypes;

export { directionShape, alignItemsShape, justifyContentShape, shrinkShape };
