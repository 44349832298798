import { useState, useCallback } from 'react';
import createStrictContext from '@/helpers/createStrictContext';

const [LayoutContextProvider, useLayoutContext] = createStrictContext({
  name: 'Layout',
});

LayoutContextProvider.displayName = 'LayoutContextProvider';

const defaultProps = {};

const propTypes = {};

export function LayoutProvider({ children }) {
  const [state, setState] = useState({
    modalIsOpen: false,
  });

  const setLayoutContext = useCallback(
    (newState) => {
      setState({
        ...state,
        ...newState,
      });
    },
    [state, setState]
  );

  const getContextValue = useCallback(
    () => ({
      ...state,
      setLayoutContext,
    }),
    [state, setLayoutContext]
  );

  return (
    <LayoutContextProvider value={getContextValue()}>
      {children}
    </LayoutContextProvider>
  );
}

LayoutProvider.defaultProps = defaultProps;
LayoutProvider.propTypes = propTypes;

export { useLayoutContext };
