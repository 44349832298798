import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

export default function Analytics() {
  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER,
    });
  }, []);

  return null;
}
