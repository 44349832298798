import { createRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Tippy from '@tippyjs/react/headless';

function renderTooltipDefault(tooltip, addDefaultClassName = true, attrs = {}) {
  return (
    <div tabIndex="-1" {...attrs}>
      <div
        className={clsx(
          addDefaultClassName &&
            'transform rounded-full bg-gray-900 px-3 py-1 text-sm font-medium leading-normal text-gray-200 shadow transition duration-300 ease-in-out'
        )}
      >
        {tooltip}
      </div>
    </div>
  );
}

export default function Tooltip({
  tooltip,
  addDefaultClassName,
  show,
  children,
  renderTooltip,
  ...tippyProps
}) {
  if (show === false) {
    return children;
  }

  const el = createRef(null);

  return (
    <Tippy
      ref={el}
      render={(attrs) => renderTooltip(tooltip, addDefaultClassName, attrs)}
      {...tippyProps}
    >
      {children}
    </Tippy>
  );
}

Tooltip.defaultProps = {
  addDefaultClassName: true,
  show: true,
  renderTooltip: renderTooltipDefault,
};

Tooltip.propTypes = {
  tooltip: PropTypes.node.isRequired,
  addDefaultClassName: PropTypes.bool,
  show: PropTypes.bool,
  children: PropTypes.node.isRequired,
  renderTooltip: PropTypes.func,
};
