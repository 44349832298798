import PropTypes from 'prop-types';

export default PropTypes.shape({
  file: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fixed: PropTypes.shape({
        base64: PropTypes.string,
        height: PropTypes.number,
        src: PropTypes.string,
        srcSet: PropTypes.string,
        width: PropTypes.number,
      }),
    }),
  }),
});
