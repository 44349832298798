import { Children } from 'react';
import PropTypes from 'prop-types';

import injectClassNameToChildren from '@/helpers/injectClassNameToChildren';
import injectPropsToChildren from '@/helpers/injectPropsToChildren';

const defaultProps = {
  props: {},
};

const propTypes = {
  props: PropTypes.shape({}),
};

export default function DynamicPropsInjection({
  props,
  className,
  children,
  ...rest
}) {
  return Children.map(children, (c) => {
    const classes = injectClassNameToChildren(c, className);

    return injectPropsToChildren(c, {
      ...props,
      ...rest,
      className: classes,
    });
  });
}

DynamicPropsInjection.defaultProps = defaultProps;
DynamicPropsInjection.propTypes = propTypes;
