import PropTypes from 'prop-types';
import clsx from 'clsx';
import Link from '@/components/Link';
import ListItem from '@/components/ListItem';
import DynamicPropsInjection from '@/components/DynamicPropsInjection';
import uniqueKey from '@/helpers/uniqueKey';
import ShapeInnerRef from '@/shapes/InnerRef';
import MenuItemCaret from './MenuItemCaret';

const defaultProps = {
  url: '',
  isHovering: false,
  hasItems: false,
  showCaret: false,
  children: null,
  innerRef: null,
};

const propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  isHovering: PropTypes.bool,
  hasItems: PropTypes.bool,
  showCaret: PropTypes.bool,
  children: PropTypes.node,
  innerRef: ShapeInnerRef,
};

export default function MenuItem({
  title,
  url,
  isHovering,
  hasItems,
  showCaret,
  children,
  innerRef,
}) {
  const commonProps = {
    'data-toggle': 'dropdown',
    'aria-haspopup': true,
    'aria-expanded': isHovering || false,
  };

  const className = clsx(
    'relative flex items-center px-2 py-2 font-medium lg:font-semibold',
    'lg:text-base lg:font-medium lg:h-20',
    'focus:outline-none',
    isHovering === false && 'text-gray-900 z-10',
    isHovering === true && 'text-green-500 z-50'
  );

  let childs = [title];

  if (hasItems && showCaret) {
    childs = [
      ...childs,
      <div key={uniqueKey('.itemCaret')} className="ml-1">
        <MenuItemCaret />
      </div>,
    ];
  }

  if (hasItems && isHovering) {
    childs = [
      ...childs,
      <div
        key={uniqueKey('.itemHoverOffsetLeft')}
        className="h-full w-6 absolute left-0 -ml-4"
      />,
      <div
        key={uniqueKey('.itemHoverOffsetLeftBottom')}
        className="h-6 w-10 absolute left-0 bottom-0 -ml-10"
      />,
      <div
        key={uniqueKey('.itemHoverOffsetRight')}
        className="h-full w-6 absolute right-0 -mr-4"
      />,
      <div
        key={uniqueKey('.itemHoverOffsetRightBottom')}
        className="h-6 w-10 absolute right-0 bottom-0 -mr-10"
      />,
      <div
        key={uniqueKey('.itemBorder')}
        className="absolute h-0.5 bg-green-500 left-0 right-0 bottom-0 z-50 -mb-0.5 mx-2 rounded"
      />,
    ];
  }

  let content;

  if (url !== null) {
    content = (
      <Link className={className} href={url}>
        {childs}
      </Link>
    );
  } else {
    content = (
      <button className={className} type="button">
        {childs}
      </button>
    );
  }

  return (
    <ListItem className="flex" innerRef={innerRef}>
      <DynamicPropsInjection props={commonProps}>
        {content}
      </DynamicPropsInjection>
      {children}
    </ListItem>
  );
}

MenuItem.defaultProps = defaultProps;
MenuItem.propTypes = propTypes;
