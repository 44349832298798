import { createElement, useEffect } from 'react';
import Head from 'next/head';
import * as hubSpotAnalytics from './hubSpotAnalytics';
import oneline from './oneline';

const isLocal = () =>
  typeof window.location !== 'undefined' && location.hostname === 'localhost';

const isDevelopment = () => process.env.NODE_ENV !== 'production';

const routerIsDefined = (router) =>
  router && router.events && typeof router.events.on === 'function';

const withHubSpot =
  (trackingCode, { router } = {}) =>
  (Page) => {
    function WithHubSpotTrackingCode(props) {
      useEffect(() => {
        if (isLocal() || isDevelopment() || !routerIsDefined(router)) {
          return null;
        }

        const handleRouteChange = (url) => {
          alert(url);
          hubSpotAnalytics.onRouteUpdate(url);
        };

        router.events.on('routeChangeStart', handleRouteChange);

        return () => router.events.off('routeChangeStart', handleRouteChange);
      }, []);

      return createElement(Page, props, [
        <Head key="aibesorg-hubspot-head">
          <script
            type="text/javascript"
            id="hs-script-loader"
            key={`aibesorg-hubspot`}
            async
            defer
            src={`//js.hs-scripts.com/${trackingCode}.js`}
            dangerouslySetInnerHTML={{
              __html: oneline`
              var _hsq = window._hsq = window._hsq || [];
              _hsq.push(['setPath', window.location.pathname + window.location.search + window.location.hash]);
              if (window.doNotTrack || navigator.doNotTrack || navigator.msDoNotTrack || 'msTrackingProtectionEnabled' in window.external) {
                if (window.doNotTrack == "1" || navigator.doNotTrack == "yes" || navigator.doNotTrack == "1" || navigator.msDoNotTrack == "1" || window.external.msTrackingProtectionEnabled()) {
                  _hsq.push(['doNotTrack']);
                }
              }
            }`,
            }}
          />
        </Head>,
      ]);
    }

    if (Page.getInitialProps) {
      WithHubSpotTrackingCode.getInitialProps = Page.getInitialProps;
    }

    return WithHubSpotTrackingCode;
  };

export default withHubSpot;
