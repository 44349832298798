import { cloneElement } from 'react';

export default function injectPropsToChildren(children, otherProps = {}) {
  if (!children) {
    return {};
  }

  const childrenProps = children.props ? children.props : {};

  return cloneElement(children, {
    ...childrenProps,
    ...otherProps,
  });
}
