export default [
  'max-w-xs',
  'max-w-sm',
  'max-w-md',
  'max-w-lg',
  'max-w-xl',
  'max-w-2xl',
  'max-w-3xl',
  'max-w-4xl',
  'max-w-5xl',
  'max-w-6xl',
  'max-w-full',
  'max-w-screen-sm',
  'max-w-screen-md',
  'max-w-screen-lg',
  'max-w-screen-xl',
  'max-w-none',
];
