import PropTypes from 'prop-types';

const defaultProps = {
  headerIsHover: false,
};

const propTypes = {
  headerIsHover: PropTypes.bool,
};

export default function HeaderHoverBack({ headerIsHover }) {
  if (headerIsHover === false) {
    return null;
  }

  return (
    <div className="absolute top-0 bottom-0 left-0 right-0 z-10 hidden w-full h-full bg-white pointer-events-none lg:block" />
  );
}

HeaderHoverBack.defaultProps = defaultProps;
HeaderHoverBack.propTypes = propTypes;
