import { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Header from './Header';
import HeaderMobile from './HeaderMobile';

const defaultProps = {
  fixed: false,
  sticky: true,
};

const propTypes = {
  fixed: PropTypes.bool,
  sticky: PropTypes.bool,
};

export default function HeaderWrapper({ fixed, sticky, ...props }) {
  return (
    <Fragment>
      <div className={clsx('hidden lg:block', sticky && 'sticky top-0 z-50')}>
        <Header
          fixed={fixed}
          sticky={sticky}
          hasAnAnnouncement={true}
          {...props}
        />
      </div>
      <div className="relative z-50 block lg:hidden">
        <HeaderMobile fixed={fixed} sticky={sticky} {...props} />
      </div>
      <div className="w-2/4 h-px bg-gradient-to-r from-black to-transparent opacity-10" />
    </Fragment>
  );
}

HeaderWrapper.defaultProps = defaultProps;
HeaderWrapper.propTypes = propTypes;
