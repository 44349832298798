import { Fragment } from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import Header from '@/view/Header';
import Main from '@/view/Main';

const Footer = dynamic(() => import('@/view/Footer'));
Footer.displayName = 'Footer';

const CookieConsent = dynamic(() => import('@/components/CookieConsent'));
CookieConsent.displayName = 'CookieConsent';

const defaultProps = {
  fixedHeader: false,
  stickyHeader: true,
  beforeHeader: null,
  footerProps: {},
};

const propTypes = {
  fixedHeader: PropTypes.bool,
  stickyHeader: PropTypes.bool,
  beforeHeader: PropTypes.node,
  footerProps: PropTypes.object,
  children: PropTypes.node.isRequired,
};

function BasicLayout({
  fixedHeader,
  stickyHeader,
  beforeHeader,
  footerProps,
  children,
}) {
  return (
    <Fragment>
      <div className="flex flex-col min-h-screen">
        {beforeHeader}
        <Header fixed={fixedHeader} sticky={stickyHeader} />
        <Main>{children}</Main>
        <Footer {...footerProps} />
      </div>
      <CookieConsent />
    </Fragment>
  );
}

BasicLayout.defaultProps = defaultProps;
BasicLayout.propTypes = propTypes;

export default BasicLayout;
