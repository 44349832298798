import { Fragment } from 'react';
import PropTypes from 'prop-types';
import Link from '@/components/Link';
import Logo from '@/view/Logo';
import Name from './Name';

const defaultProps = {
  hideName: false,
  showPartialWithAnimation: false,
  noLink: false,
  logoSize: 'base',
};

const propTypes = {
  hideName: PropTypes.bool,
  showPartialWithAnimation: PropTypes.bool,
  noLink: PropTypes.bool,
  logoSize: PropTypes.string,
};

export default function Brand({ hideName, noLink, logoSize }) {
  const hasName = hideName === false;

  const content = (
    <Fragment>
      <Logo key="logo" size={logoSize} />
      {hasName && <Name key="logo-name" />}
    </Fragment>
  );

  if (noLink) {
    return content;
  }

  return (
    <Link
      title="Inicio"
      className="flex items-center space-x-2 overflow-hidden"
      href="/"
    >
      {content}
    </Link>
  );
}

Brand.defaultProps = defaultProps;
Brand.propTypes = propTypes;
