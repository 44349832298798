import PropTypes from 'prop-types';
import Flex from '@/components/Flex';

const brandFullName = 'Asociación Iberoamericana de Scrum';

const BrandShortName = () => (
  <div className="inline-flex items-center text-2xl font-extrabold tracking-wide text-gray-900">
    AIBE
    <span className="text-green-500">S</span>
  </div>
);

const defaultProps = {
  short: true,
};

const propTypes = {
  short: PropTypes.bool,
};

const Name = ({ short }) => (
  <Flex>
    {short === true && <BrandShortName />}
    {short === false && brandFullName}
  </Flex>
);

Name.defaultProps = defaultProps;
Name.propTypes = propTypes;

export default Name;
