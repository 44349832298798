import Tooltip from '@/components/Tooltip';

export default function AccessAction() {
  return (
    <Tooltip tooltip="Acceder al examen de certificación">
      <a
        href={process.env.NEXT_PUBLIC_EXAMS_APP}
        className="inline-flex items-center space-x-2 rounded-full border border-transparent bg-gray-900 py-2 px-4 text-base font-semibold leading-none text-gray-100 hover:bg-gray-800 hover:text-gray-200 focus:bg-gray-800 focus:text-white focus:outline-none focus:ring-4 focus:ring-gray-900 focus:ring-opacity-20"
      >
        Acceder
      </a>
    </Tooltip>
  );
}
