import { createElement } from 'react';
import Router from 'next/router';
import withFBQ from './withFBQ';
import withHubSpot from '@/domain/HubSpot';

const withAnalytics = (App) => {
  function WithAnalytics(props) {
    return createElement(App, props);
  }

  if (App.getInitialProps) {
    WithAnalytics.getInitialProps = App.getInitialProps;
  }

  const WithFBQAnalytics = withFBQ(
    process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID,
    Router
  )(WithAnalytics);

  const WithHubSpotAnalytics = withHubSpot(
    process.env.NEXT_PUBLIC_HUBSPOT_TRACKING_CODE,
    Router
  )(WithFBQAnalytics);

  return WithHubSpotAnalytics;
};

export default withAnalytics;
