import PropTypes from 'prop-types';

global.Element = typeof Element === 'undefined' ? () => {} : global.Element;

export default PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({
    current: PropTypes.instanceOf(global.Element),
  }),
]);
