import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ShapeMaxWidth } from '@/shapes';

const defaultProps = {
  autoMargin: true,
  className: '',
  maxWidth: 'max-w-screen-xl',
  padding: true,
  children: null,
};

const propTypes = {
  autoMargin: PropTypes.bool,
  className: PropTypes.string,
  maxWidth: ShapeMaxWidth,
  padding: PropTypes.bool,
  children: PropTypes.node,
};

export default function Container({
  autoMargin,
  className,
  maxWidth,
  padding,
  children,
  ...props
}) {
  return (
    <div
      className={clsx(
        'container mx-auto',
        maxWidth,
        {
          'px-6 lg:px-8 xl:px-10': padding,
          'mx-auto': maxWidth && maxWidth !== 'max-w-none' && autoMargin,
        },
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}

Container.defaultProps = defaultProps;
Container.propTypes = propTypes;

export { defaultProps, propTypes, ShapeMaxWidth };
