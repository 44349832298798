import PropTypes from 'prop-types';
import UnorderedList from '@/components/UnorderedList';
import MenuItemNormal from '@/view/MainMenu/MenuItemNormal';
import uniqueKey from '@/helpers/uniqueKey';
import navigation from '@/content/navigation.json';

export default function MainMenu({ onHoverChange }) {
  return (
    <UnorderedList className="flex items-center">
      {navigation.primary.map(({ slug, title, url, items }) => {
        return (
          <MenuItemNormal
            key={uniqueKey(url)}
            title={title}
            url={url}
            slug={slug}
            hasItems={items && items.length !== 0}
            onHoverChange={(isHover) => onHoverChange(isHover, slug)}
          />
        );
      })}
    </UnorderedList>
  );
}

MainMenu.defaultProps = {
  onHoverChange: () => null,
};

MainMenu.propTypes = {
  onHoverChange: PropTypes.func,
};
