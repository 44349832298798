import PropTypes from 'prop-types';
import ToggleDefault from './ToggleDefault';
import ToggleClose from './ToggleClose';

const defaultProps = {
  isActive: false,
  onToggle: () => {},
};

const propTypes = {
  isActive: PropTypes.bool,
  onToggle: PropTypes.func,
};

export default function HeaderMobileNavToggle({ isActive, onToggle }) {
  return (
    <button
      type="button"
      className="focus:shadow-outline-gray rounded border border-transparent p-2 focus:border-gray-400 focus:outline-none"
      data-toggle="navbar"
      aria-expanded="false"
      aria-label="Alternar navegación"
      onClick={onToggle}
    >
      <div className="w-8">
        {isActive === false && <ToggleDefault />}
        {isActive === true && <ToggleClose />}
      </div>
    </button>
  );
}

HeaderMobileNavToggle.defaultProps = defaultProps;
HeaderMobileNavToggle.propTypes = propTypes;
