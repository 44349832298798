import { Toaster } from 'react-hot-toast';

export default function ToasterProvider() {
  return (
    <Toaster
      toastOptions={{
        style: {
          margin: '40px',
          background: '#111827',
          color: '#FFFFFF',
        },
        duration: 5000,
        success: {
          duration: 3000,
        },
      }}
    />
  );
}
