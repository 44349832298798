import PropTypes from 'prop-types';
import clsx from 'clsx';
import ShapeInnerRef from '@/shapes/InnerRef';

const defaultProps = {
  className: null,
  children: null,
  innerRef: null,
};

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  innerRef: ShapeInnerRef,
};

export default function ListItem({ className, children, innerRef, ...props }) {
  return (
    <li
      className={clsx(className, 'flex items-center')}
      ref={innerRef}
      {...props}
    >
      {children}
    </li>
  );
}

ListItem.defaultProps = defaultProps;
ListItem.propTypes = propTypes;
