import PropTypes from 'prop-types';

const defaultProps = {
  className: '',
  children: null,
};

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default function UnorderedList({ className, children, ...otherProps }) {
  return (
    <ul className={className} {...otherProps}>
      {children}
    </ul>
  );
}

UnorderedList.defaultProps = defaultProps;
UnorderedList.propTypes = propTypes;
