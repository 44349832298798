import Link from '@/components/Link';
import Tooltip from '@/components/Tooltip';

export default function CertificationsAction() {
  return (
    <Tooltip tooltip="Todas nuestras certificaciones">
      <Link
        href="/certificaciones"
        className="inline-flex items-center rounded-full border border-transparent bg-green-500 py-2 px-3 text-base font-semibold leading-none text-white ring-4 ring-transparent hover:bg-green-600 focus:bg-green-600 focus:outline-none focus:ring-green-800 focus:ring-opacity-20"
      >
        Certificaciones
      </Link>
    </Tooltip>
  );
}
