const hubSpotDefined = () =>
  typeof window._hsq !== 'undefined' && Array.isArray(window._hsq);

export function onRouteUpdate(url) {
  if (hubSpotDefined() && process.env.NODE_ENV === 'production') {
    const trackPageView = () => {
      window._hsq.push(['setPath', url]);
      window._hsq.push(['trackPageView']);
    };

    if (typeof window.requestAnimationFrame === 'function') {
      window.requestAnimationFrame(() => {
        window.requestAnimationFrame(trackPageView);
      });
    } else {
      // simulate 2 requestAnimationFrame calls
      setTimeout(trackPageView, 32);
    }
  }
}
