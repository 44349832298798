import clsx from 'clsx';

export default function injectClassNameToChildren(children, className = '') {
  if (!children) {
    return null;
  }

  const definedClassName =
    'className' in children.props ? children.props.className : null;

  return clsx(definedClassName, className);
}
