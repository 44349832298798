import PropTypes from 'prop-types';
import clsx from 'clsx';

const defaultProps = {
  size: 'base',
};

const propTypes = {
  size: PropTypes.oneOf(['base', 'lg', 'xl']),
};

export default function Logo({ size }) {
  return (
    <div className="inline-flex">
      <div className={clsx('aibes-logo', size)}>
        <span className="primary" />
        <span className="secondary" />
        <span className="tertiary" />
      </div>
    </div>
  );
}

Logo.defaultProps = defaultProps;
Logo.propTypes = propTypes;
